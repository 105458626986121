.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

body, html  {
  position: relative;
  overflow-x: hidden;
}